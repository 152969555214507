import React from 'react';
import PlacementCard from './PlacementCard';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';

const PlacementHighlightBanner = () => {
  return (
    <section className="mx-auto flex max-w-6xl flex-col items-center gap-5 px-4 py-12 text-center">
      {/* Top highlight section */}
      <PlacementCard />

      {/* Bottom CTA */}
      <h2 className="text-[20px] font-bold md:text-3xl">
        2000+ Learners Placed In Top Companies
      </h2>
      <LeadGenerationButton
        type="Book Your Trial, Now"
        buttonLocation={'PLACEMENT_PAGE'}
        className={'w-[250px]'}
      />
    </section>
  );
};

export default PlacementHighlightBanner;
