import React from 'react';

const testimonials = [
  {
    quote:
      'Crio presented us with a diverse pool of candidates who not only met our job specifications but also aligned well with our company values',
    name: 'Kajal Kundanani',
    title: 'Assistant HR Manager',
    company: 'CommVersion',
    logo: 'CImage1.png',
    avatar: 'RImage1.png',
  },
  {
    quote:
      "The candidates they presented were all highly qualified and a great fit for our team. We'll definitely be using Crio.Do again for future hiring needs!",
    name: 'Yash Singhal',
    title: 'Founder',
    company: 'Revly',
    logo: 'CImage2.png',
    avatar: 'RImage2.png',
  },
  {
    quote:
      'The team truly excelled in bringing quality candidates that aligned with our job specifications.',
    name: 'Neha Daniel',
    title: 'Human Resources Recruiter',
    company: 'Vizmo',
    logo: 'CImage3.png',
    avatar: 'RImage5.png',
  },
];

const RecruitersReport = () => {
  return (
    <section className="bg-white py-12 text-center">
      <h2 className="mb-10 text-3xl font-bold">Recruiter’s Report</h2>
      <div className="flex justify-center gap-6 overflow-x-auto px-4">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="relative min-w-[300px] max-w-md rounded-xl bg-green-50 p-6 shadow-md"
          >
            <p className="text-md mb-6">“{testimonial.quote}”</p>
            <div className="mt-4 flex flex-col items-center">
              <div className="mb-2 h-16 w-16 overflow-hidden rounded-full">
                <img
                  src={`/images/Testimonial/${testimonial.avatar}`}
                  alt={testimonial.name}
                  width={64}
                  height={64}
                  className="object-cover"
                />
              </div>
              <h4 className="text-lg font-semibold">{testimonial.name}</h4>
              <p className="text-sm text-gray-500">{testimonial.title}</p>
              <div className="mt-1">
                <img
                  src={`/images/Testimonial/${testimonial.logo}`}
                  alt={testimonial.company}
                  width={100}
                  height={20}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default RecruitersReport;
