import React from 'react';
import MenuPopover from '../MenuPopover/index';
import { links, programLinks } from '@src/constants/NavigationLinks/index';
import { Link } from 'gatsby';

/**
 * NavigationLinks component renders the navigation links and associated popovers.
 *
 * @returns {JSX.Element} - Navigation links component JSX.
 */

export default function NavigationLinks() {
  return (
    <>
      {/* Render Programs menu popover */}
      <MenuPopover linksArray={programLinks} name="Programs" />
      {/* Render individual navigation links */}
      {links.map((el, idx) => (
        <Link to={el.link} key={idx}>
          {el.name}
        </Link>
      ))}
    </>
  );
}
