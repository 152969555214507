import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const CompanyMarquee = () => {
  const marqueeRef = useRef(null);

  useEffect(() => {
    let animationFrame;
    const scroll = () => {
      if (marqueeRef.current) {
        marqueeRef.current.scrollLeft += 1; // Speed
        if (
          marqueeRef.current.scrollLeft >=
          marqueeRef.current.scrollWidth / 2
        ) {
          marqueeRef.current.scrollLeft = 0;
        }
      }
      animationFrame = requestAnimationFrame(scroll);
    };
    animationFrame = requestAnimationFrame(scroll);
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  const LogoStrip = () => (
    <div className="flex items-center gap-10 pr-10">
      <StaticImage
        src="../../images/PlacementsPage/Companies/Flipkart.png"
        alt="Flipkart"
        className="h-[60px] w-[130px]"
      />
      <StaticImage
        src="../../images/PlacementsPage/Companies/Groww.png"
        alt="Groww"
        className="h-[60px] w-[130px]"
      />
      <StaticImage
        src="../../images/PlacementsPage/Companies/BharatPe.png"
        alt="BharatPe"
        className="h-[60px] w-[130px]"
      />
      <StaticImage
        src="../../images/PlacementsPage/Companies/Postman.png"
        alt="Postman"
        className="h-[60px] w-[130px]"
      />
      <StaticImage
        src="../../images/PlacementsPage/Companies/Meesho.png"
        alt="Meesho"
        className="h-[60px] w-[130px]"
      />
      <StaticImage
        src="../../images/PlacementsPage/Companies/Shiprocket.png"
        alt="Shiprocket"
        className="h-[60px] w-[130px]"
      />
      <StaticImage
        src="../../images/PlacementsPage/Companies/Amazon.png"
        alt="Amazon"
        className="h-[60px] w-[130px]"
      />
    </div>
  );

  return (
    <section className="relative bg-white py-12">
      <h2 className="mb-8 text-center text-2xl font-bold text-black md:text-3xl">
        Work At Tech Unicorns, Soonicorns And MNCs
      </h2>

      <div className="relative overflow-hidden">
        {/* Blur overlays */}
        <div className="pointer-events-none absolute left-0 top-0 z-10 h-full w-16 bg-gradient-to-r from-white via-white/70 to-transparent" />
        <div className="pointer-events-none absolute right-0 top-0 z-10 h-full w-16 bg-gradient-to-l from-white via-white/70 to-transparent" />

        {/* Scrollable marquee container */}
        <div
          ref={marqueeRef}
          className="no-scrollbar overflow-x-scroll"
          style={{ whiteSpace: 'nowrap' }}
        >
          <div className="inline-flex gap-20 px-6">
            <LogoStrip />
            <LogoStrip />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyMarquee;
