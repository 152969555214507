import { random } from 'lodash';
import React from 'react';

const PlacementCard = () => {
  const placedUserImages = [
    'PlacedUser1.webp',
    'PlacedUser2.webp',
    'PlacedUser3.webp',
    'PlacedUser4.webp',
    'PlacedUser5.webp',
  ];

  return (
    <div className="flex items-center justify-center">
      <img
        src={`/images/Testimonial/${placedUserImages[random(0, 3)]}`}
        alt="Duplicate"
        className="mx-4 w-full object-cover md:h-[400px]"
      />
    </div>
  );
};

export default PlacementCard;
