import { useEffect, useRef } from 'react';
import React from 'react';

export default function VideoWithFullscreen({ story }) {
  const playerRef = useRef(null);

  useEffect(() => {
    // Load YouTube IFrame Player API
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Initialize the player once API is ready
    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('yt-player', {
        events: {
          onReady: () => {},
        },
      });
    };
  }, []);

  const skip = (seconds) => {
    const player = playerRef.current;
    if (player && player.getCurrentTime && player.seekTo) {
      const currentTime = player.getCurrentTime();
      player.seekTo(currentTime + seconds, true);
    }
  };

  const handleFullscreen = () => {
    const iframe = document.getElementById('yt-player');
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  return (
    <div className="relative mb-4">
      <div className="pointer-events-none">
        <iframe
          id="yt-player"
          src={`${story?.videoUrl}?enablejsapi=1&autoplay=1&rel=0&modestbranding=1`}
          title="Success Story"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="w-full rounded-md md:h-[300px]"
        ></iframe>
      </div>

      <div className="absolute bottom-2 right-2 z-10 flex gap-2">
        {/* <button
          onClick={() => skip(-10)}
          className="rounded bg-black/60 px-2 py-1 text-white hover:bg-black/80"
        >
          ⏪ 10s
        </button> */}
        {/* <button
          onClick={() => skip(10)}
          className="rounded bg-black/60 px-2 py-1 text-white hover:bg-black/80"
        >
          10s ⏩
        </button> */}
        <button
          onClick={handleFullscreen}
          className="rounded bg-black/60 px-2 py-1 text-white hover:bg-black/80"
        >
          ⛶
        </button>
      </div>
    </div>
  );
}
