import React from 'react';
import db from '../data/db.json';

const placementStats = db.strapi_metadata.crioStats['placement-stats'];

export const statsV2 = [
  {
    title: placementStats.at(0).content,
    subtitle: `placed within ${placementStats
      ?.at(0)
      .label.match(/\d+/g)
      .map(Number)} months of graduation`,
    backgroundColor: '#D5E927',
  },
  {
    title: placementStats.at(1).content,
    subtitle: placementStats.at(1).label,
    backgroundColor: '#ABED4F',
  },
  {
    title: placementStats?.at(4).content,
    subtitle: placementStats?.at(4).label,
    backgroundColor: '#57F49E',
  },
  {
    title: placementStats?.at(3).content,
    subtitle: placementStats?.at(3).label,
    backgroundColor: '#2DF8C5',
  },
];

export const stats = [
  {
    title: '93%',
    subtitle: 'placed within 8 months of graduation',
    backgroundColor: '#D5E927',
  },
  {
    title: '11 LPA',
    subtitle: (
      <>
        Average
        <br /> CTC
      </>
    ),
    backgroundColor: '#ABED4F',
  },
  {
    title: '89%',
    subtitle: (
      <>
        Average <br /> Salary Hike
      </>
    ),
    backgroundColor: '#57F49E',
  },
  {
    title: '700+',
    subtitle: (
      <>
        Hiring <br /> Partners
      </>
    ),
    backgroundColor: '#2DF8C5',
  },
];

export const UnicornCompanyImages = [
  {
    title: 'Groww',
    imgUrl: 'Groww',
  },
  {
    title: 'Slice',
    imgUrl: 'Slice',
  },
  {
    title: 'BharatPe',
    imgUrl: 'BharatPe',
  },
  {
    title: 'Postman',
    imgUrl: 'Postman',
  },
  {
    title: 'Grofers',
    imgUrl: 'Grofers',
  },
  {
    title: 'CRED',
    imgUrl: 'CRED',
  },
  {
    title: 'Cars24',
    imgUrl: 'Cars24',
  },
  {
    title: 'UrbanCompany',
    imgUrl: 'UrbanCompany',
  },
  {
    title: 'Meesho',
    imgUrl: 'Meesho',
  },
  {
    title: 'NoBroker',
    imgUrl: 'NoBroker',
  },
  {
    title: 'PharmEasy',
    imgUrl: 'PharmEasy',
  },
  {
    title: 'upGrad',
    imgUrl: 'upGrad',
  },
  {
    title: 'Ola',
    imgUrl: 'Ola',
  },
  {
    title: 'Flipkart',
    imgUrl: 'Flipkart',
  },
  {
    title: 'Dailyhunt',
    imgUrl: 'Dailyhunt',
  },
  {
    title: 'PayTm',
    imgUrl: 'PayTm',
  },
  {
    title: 'Zoho',
    imgUrl: 'Zoho',
  },
];

export const SoonicornCompanyImages = [
  {
    title: 'Jumbotail',
    imgUrl: 'Jumbotail',
  },
  {
    title: 'Ninjacart',
    imgUrl: 'Ninjacart',
  },
  {
    title: 'Ula',
    imgUrl: 'Ula',
  },
  {
    title: 'Shiprocket',
    imgUrl: 'Shiprocket',
  },
  {
    title: 'Tracxn',
    imgUrl: 'Tracxn',
  },
  {
    title: 'Ather',
    imgUrl: 'Ather',
  },
  {
    title: 'Fampay',
    imgUrl: 'Fampay',
  },
  {
    title: 'RupeeK',
    imgUrl: 'RupeeK',
  },
  {
    title: 'Lendingkart',
    imgUrl: 'Lendingkart',
  },
  {
    title: 'Superset',
    imgUrl: 'Superset',
  },
  {
    title: 'Navi',
    imgUrl: 'Navi',
  },
  {
    title: 'Juspay',
    imgUrl: 'Juspay',
  },
  {
    title: 'Capillary',
    imgUrl: 'Capillary',
  },
  {
    title: 'WebEngage',
    imgUrl: 'WebEngage',
  },
  {
    title: 'Vouch',
    imgUrl: 'Vouch',
  },
  {
    title: 'Vicara',
    imgUrl: 'Vicara',
  },
  {
    title: 'YellowClass',
    imgUrl: 'YellowClass',
  },
  {
    title: 'Briq',
    imgUrl: 'Briq',
  },
];

export const MNCsCompanyImages = [
  {
    title: 'Microsoft',
    imgUrl: 'Microsoft',
  },
  {
    title: 'Amazon',
    imgUrl: 'Amazon',
  },
  {
    title: 'Grab',
    imgUrl: 'Grab',
  },
  {
    title: 'Salesforce',
    imgUrl: 'Salesforce',
  },
  {
    title: 'Oracle',
    imgUrl: 'Oracle',
  },
  {
    title: 'IBM',
    imgUrl: 'IBM',
  },
  {
    title: 'Arcesium',
    imgUrl: 'Arcesium',
  },
  {
    title: 'Walmart',
    imgUrl: 'Walmart',
  },
  {
    title: 'Tekion',
    imgUrl: 'Tekion',
  },
  {
    title: 'Vmware',
    imgUrl: 'Vmware',
  },
  {
    title: 'DEShaw',
    imgUrl: 'DEShaw',
  },
  {
    title: 'Deloitte',
    imgUrl: 'Deloitte',
  },
  {
    title: 'PwC',
    imgUrl: 'PwC',
  },
  {
    title: 'Accenture',
    imgUrl: 'Accenture',
  },
];

export const SocialImages = [
  {
    title: 'LinkedIn',
    imageName: 'Linkedin',
    url: 'https://www.linkedin.com/company/criodo/?utm_source=crio-website',
  },
  {
    title: 'Youtube',
    imageName: 'Youtube',
    url: 'https://www.youtube.com/channel/UCE1_bZxswCCARhhVeSQLuew/?utm_source=crio-website',
  },
  {
    title: 'Instagram',
    imageName: 'Instagram',
    url: 'https://www.instagram.com/crio.do/?utm_source=crio-website',
  },
  {
    title: 'Twitter',
    imageName: 'Twitter',
    url: 'https://twitter.com/crio_do/?utm_source=crio-website',
  },
  {
    title: 'Facebook',
    imageName: 'Facebook',
    url: 'https://www.facebook.com/criodo?utm_source=crio-website',
  },
];

export const Testimonials = [
  {
    image: 'Atul',
    name: 'Atul Kashyap',
    role: 'Software Engineer',
    company: 'Briq-Green',
    linkedInUrl: 'https://www.linkedin.com/in/atul-kashyap-1208/',
    testimonial:
      'At Crio, you learn how projects are built in companies. The learning is fast-paced yet structured. The project modules helped me immensely during interviews.',
  },
  {
    image: 'Surya',
    name: 'Surya Saini',
    role: 'SDE-1',
    company: 'Amazon-Green',
    linkedInUrl: 'https://www.linkedin.com/in/suryasaini/',
    testimonial:
      'The modules and tasks were similar to what we would do in a real job. Apart from a great resume, my problem-solving skills improved a lot too.',
  },
  {
    image: 'Hasan',
    name: 'Hasan Shaikh',
    role: 'Full Stack Developer',
    company: 'Briq-Green',
    linkedInUrl: 'https://www.linkedin.com/in/hasanshaikh/',
    testimonial:
      'When I implemented what I learned at Crio, I saw a real improvement in my coding style. After completing the program, I was hoping to get multiple offers from product companies- fortunately, I did.',
  },
  {
    image: 'Sayanika',
    name: 'Sayanika Dutta',
    role: 'Engineer',
    company: 'TataElxsi-Green',
    linkedInUrl: 'https://www.linkedin.com/in/sayanika-dutta-8178a4166/',
    testimonial:
      'I learned how to write code that was clean, precise, and modular. I learned how to deploy websites. Every time I appeared for interviews, I felt confident I would be selected.',
  },
  {
    image: 'Karthik',
    name: 'Karthik Chennupati',
    role: 'Software Development Engineer',
    company: 'Amazon-Green',
    linkedInUrl: 'https://www.linkedin.com/in/chkarthik1729/',
    testimonial:
      'I learned how to build real projects in a simple & understandable way. I know that is a kind of work I should be doing early in my career. I got the right guidance & support here to achieve that.',
  },
  {
    image: 'Deval',
    name: 'Deval Sethi',
    role: 'SDE-1',
    company: 'Apna-Green',
    linkedInUrl: 'https://www.linkedin.com/in/devalsethi/',
    testimonial:
      'There’s a Crio Problem Solving Methodology that’s a full proof paradigm for solving problems. The projects are a replica of real-world experiences which really helped me make an impact during interviews.',
  },
  {
    image: 'Pranav',
    name: 'Pranav Nigam',
    role: 'Software Engineer',
    company: 'Walmart-Green',
    linkedInUrl: 'https://www.linkedin.com/in/pranav-nigam001/',
    testimonial:
      'At Crio, I got to learn in a systematic way. The projects are very well directed and very much related to industry experience. I learnt how to solve problems on my own.',
  },
  {
    image: 'Keerthana',
    name: 'Keerthana Koorapati',
    role: 'Full Stack Developer',
    company: 'Ather-Green',
    linkedInUrl: 'https://www.linkedin.com/in/keerthana-koorapati/',
    testimonial:
      'These projects, for an experienced professional, definitely help you get noticed by recruiters. The projects were really unique & that was something my recruiters were definitely impressed with.',
  },
  {
    image: 'Sanketh',
    name: 'Sanketh S',
    role: 'SDE-1',
    company: 'Rubix-Green',
    linkedInUrl: 'https://www.linkedin.com/in/sanketh-s-7ba53213a/',
    testimonial:
      'At Crio, my learning curve skyrocketed. Now, I can do everything from development to deployment. Crio is one of the best decisions and investments I have made in my life.',
  },
  {
    image: 'Adriza',
    name: 'Adriza Mishra',
    role: 'SDE Intern',
    company: 'Honeywell-Green',
    linkedInUrl: 'https://www.linkedin.com/in/adriza-mishra/',
    testimonial:
      'Working on projects in a systematic way & understanding how to remove the errors in a logical way helped me a lot. During the interview, I was able to explain everything I had learnt and built.',
  },
  {
    image: 'Sachin',
    name: 'Sachin Thakur',
    role: 'Frontend Developer',
    company: 'NurtureFarm-Green',
    linkedInUrl: 'https://www.linkedin.com/in/sachin-thakur-582666180/',
    testimonial:
      'By doing all the real world projects at Crio, you gain a lot of confidence about solving real world problems. If you work on Crio projects, you’ll be able to crack interviews.',
  },
  {
    image: 'Namrata',
    name: 'Namrata Kumari',
    role: 'Cloud Software Developer',
    company: 'IBM-Green',
    linkedInUrl: 'https://www.linkedin.com/in/adriza-mishra/',
    testimonial:
      'Crio showed me the path and gave me the right guidance to switch my role after working for 3 years in a service-based company.',
  },
  {
    image: 'Ajay',
    name: 'Ajay Singh Bhadauriya',
    role: 'Junior Software Engineer',
    company: 'Wissen-Green',
    linkedInUrl: 'https://www.linkedin.com/in/ajay-bhadauriya/',
    testimonial:
      'After I joined Crio, I learned Javascript in a month. My mentors told me if you learn it by yourself, you’ll remember it always. At Crio, I got to know that ‘Learn by Doing’ really is the right path.',
  },
];

export const programLinks = [
  {
    title: 'Full Stack Developer Program',
    subtitle:
      'Become a skilled Full Stack developer with hands-on experience in Javascript and MERN stack.',
    link: '/full-stack-developer-track',
    icon: 'FDT',
  },
  {
    title: 'Backend Developer Program',
    subtitle:
      'Become a skilled Backend developer with hands-on experience in building a scalable web backend.',
    link: '/backend-developer-track',
    icon: 'BDT',
  },
  {
    title: 'Fellowship Program in Software Development with GenAI',
    subtitle:
      'Upskill and land your dream career as an AI-powered Full-Stack or Backend developer - Assured!',
    link: '/software-development-fellowship-program',
    icon: 'Fellowship',
  },
  {
    title: 'Masters in QA Automation Testing',
    subtitle:
      'Get practical work experience of automation testing & get software testing roles in top companies- Assured!',
    link: '/qa-automation-testing',
    icon: 'QA',
  },
];

export const links = [
  { name: 'Hire from Crio', link: '/recruit' },
  { name: 'Projects', link: '/projects' },
  { name: 'Blog', link: 'https://www.crio.do/blog/' },
  { name: 'Placement', link: '/placements' },
];

export const placementLinks = [
  {
    title: 'Placement Stats',
    subtitle:
      'Helping our Learners Get the Best Career Breakthroughs and into the Best Companies',
    link: '/placements',
    icon: 'PlacementStats',
  },
  {
    title: 'Success Stories',
    subtitle:
      'Hear from some of our learners who took their careers to new heights',
    link: '/testimonials',
    icon: 'SuccessStories',
  },
];
