import React, { useState, useRef, useEffect } from 'react';

const MultiSelectDropdown = ({
  label,
  options = [],
  selectedValues = [],
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const allSelected = selectedValues.length === options.length;

  const toggleOption = (option) => {
    if (selectedValues.includes(option)) {
      onChange(selectedValues.filter((val) => val !== option));
    } else {
      onChange([...selectedValues, option]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      ref={dropdownRef}
      className="relative w-fit rounded-[12px] border border-v5-neutral-300"
    >
      {/* Dropdown Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex w-full items-center justify-between rounded-[12px] border border-v5-neutral-300 px-4 py-2 font-rubik text-[14px] font-medium text-green-900 shadow-sm"
      >
        {label}
        {selectedValues.length > 0 ? (
          <div className="ml-2 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-v5-green-300 font-manrope text-[12px] font-bold text-v5-neutral-200">
            {selectedValues.length}
          </div>
        ) : (
          <div className="h-[20px] w-[20px]"></div>
        )}
        <svg
          className={`ml-2 h-5 w-5 transform transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute z-20 mt-2 w-[250px] overflow-hidden rounded-xl border border-gray-200 bg-white shadow-lg">
          {options.map((option) => (
            <div
              key={option}
              onClick={() => toggleOption(option)}
              className="inline-flex w-full cursor-pointer items-center justify-start gap-2 px-4 py-2 text-[16px] text-gray-800 hover:bg-v5-neutral-200"
            >
              <div className="flex">
                <input
                  type="checkbox"
                  checked={selectedValues.includes(option)}
                  readOnly
                  className="form-checkbox h-4 w-4 text-green-600"
                />
              </div>
              <span className="inline-block">
                {option === 'Non-Circuit Branch to IT Jobs'
                  ? 'Non CS to IT Jobs'
                  : option}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
