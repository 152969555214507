import CompaniesCard from '@components/CompaniesCard/index';
import React from 'react';
import StatsPanel from '../StatsPanel/index';
import {
  MNCsCompanyImages,
  SoonicornCompanyImages,
  UnicornCompanyImages,
} from '../../../PageData/data.js';
import Testimonial from '../Testimonial/index';
import SectionLayout from '../layouts/SectionLayout/index';

const CompaniesSection = (props) => {
  return (
    <SectionLayout
      {...props}
      heading={
        <>
          Work at tech unicorns,
          <br /> soonicorns, or MNCs
        </>
      }
      description={
        <>
          <strong className="font-bold text-black">
            Build your Github portfolio of projects
          </strong>{' '}
          and impress recruiters
          <br className="hidden md:block" /> at unicorns, global MNCs, and hyper
          growth startups.
        </>
      }
    >
      <div className="container">
        <div className="flex w-full flex-col items-center gap-y-5">
          <div className="flex w-full flex-col items-center">
            <CompaniesCard
              title="Unicorns"
              headerBackgroundColor="bg-v5-lime-200"
              companyImages={UnicornCompanyImages}
              className="mt-12"
              isCarousel={true}
            />
            <CompaniesCard
              title="Hyper growth startups & Soonicorns"
              headerBackgroundColor="bg-v5-lime-400"
              companyImages={SoonicornCompanyImages}
              isCarousel={true}
            />
            <CompaniesCard
              title="Global tech MNCs"
              headerBackgroundColor="bg-v5-green-100"
              companyImages={MNCsCompanyImages}
              isCarousel={true}
            />
          </div>
          {props.hideTestimonial ? (
            <></>
          ) : (
            <>
              <div className="flex flex-col gap-x-10 md:flex-row">
                <Testimonial />
              </div>
              <StatsPanel />
            </>
          )}
        </div>
      </div>
    </SectionLayout>
  );
};

export default CompaniesSection;
