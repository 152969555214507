import React, { useState, useEffect } from 'react';
import StoryModal from './StoryModal';

const slides = [
  {
    name: 'Vishesh Agarwal',
    title: 'Service to Product - Vishesh Agarwal',
    description: ``,
    highlight: '',
    image: '/images/Testimonial/TestimonialImage1.png',
    videoUrl: 'https://www.youtube.com/embed/mgdMrPC-mtg?controls=0',
  },
  {
    name: 'Priya',
    title:
      'From Mechanical Engineer to UI/UX Developer—Cracked the Interview in ONE Shot!',
    description: ``,
    highlight: '',
    image: '/images/Testimonial/TestimonialImage2.png',
    videoUrl: 'https://www.youtube.com/embed/2Jy8NyCoDT8?controls=0',
  },
  {
    name: 'Kartik Bajaj',
    title: 'From Consultant to SDE-2 at Adobe',
    image: '/images/Testimonial/TestimonialImage3.png',
    videoUrl: 'https://www.youtube.com/embed/3fAYdlTyPWU?controls=0',
  },
  {
    name: 'Pragya Kushwaha',
    title: 'This Biotech Engineer Cracked her First SDE role at SpiceJet',
    image: '/images/Testimonial/TestimonialImage4.png',
    videoUrl: 'https://www.youtube.com/embed/1RUTJ12S4DY?controls=0',
  },
  {
    name: 'Subir Dutta',
    title:
      'From Mechanical Engineer to UI/UX Developer—Cracked the Interview in ONE Shot!',
    image: '/images/Testimonial/TestimonialImage5.png',
    videoUrl: 'https://www.youtube.com/embed/48eiTb-7DaQ?controls=0',
  },
];

const Carousel = () => {
  const [current, setCurrent] = useState(0);
  const [selectedStory, setSelectedStory] = useState(null);
  const total = slides.length;

  const prev = () => setCurrent((prev) => (prev - 1 + total) % total);
  const next = () => setCurrent((prev) => (prev + 1) % total);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev + 1) % total);
    }, 5000);
    return () => clearInterval(interval);
  }, [total]);

  const { title, description, highlight, cta, image, videoUrl, name } =
    slides[current];

  return (
    <section className="relative mx-auto flex max-w-[1000px] flex-col items-center justify-between gap-10 px-4 py-12 sm:px-6 md:px-10 md:py-16 lg:flex-row">
      {/* Arrows (absolute to section, not image) */}
      <button
        onClick={prev}
        className="absolute -left-6 top-1/2 z-10 hidden -translate-y-1/2 rounded-full bg-gray-100 p-2 shadow hover:bg-gray-200 lg:flex"
      >
        <img
          src={'/images/LeftIcon.png'}
          alt="Prev"
          className="h-[32px] w-[32px] object-contain"
        />
      </button>

      <button
        onClick={next}
        className="absolute -right-6 top-1/2 z-10 hidden -translate-y-1/2 rounded-full bg-gray-100 p-2 shadow hover:bg-gray-200 lg:flex"
      >
        <img
          src={'/images/RightIcon.png'}
          alt="Next"
          className="h-[32px] w-[32px] object-contain"
        />
      </button>

      {/* Image */}
      <div className="w-full lg:order-2">
        <img
          src={image}
          alt={cta}
          className="mx-auto w-full max-w-md rounded-xl object-contain"
        />
      </div>

      {/* Text */}
      <div className="w-full max-w-xl text-center lg:order-1 lg:text-left">
        <h2 className="mb-4 text-2xl font-bold text-black sm:text-3xl lg:text-4xl">
          {title}
        </h2>
        <button
          onClick={() => setSelectedStory({ videoUrl, name: title })}
          className="mx-auto flex items-center gap-2 rounded-md bg-yellow-400 px-6 py-3 text-sm font-semibold text-black shadow-md hover:bg-yellow-500 sm:text-base lg:mx-0"
        >
          <img
            src={'/images/BlackPlayIcon.png'}
            alt="Next"
            className="h-[32px] w-[32px] object-contain"
          />
          Hear {name?.split(' ')?.[0]}'s Story
        </button>
      </div>
      <StoryModal
        story={selectedStory}
        onClose={() => setSelectedStory(null)}
      />
    </section>
  );
};

export default Carousel;
