import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const PlacementHighlight = () => {
  return (
    <div className="mx-4 my-10 max-w-5xl rounded-[32px] bg-[#72F8C4] px-6 py-10 text-center md:mx-auto">
      <div className="flex flex-col items-center justify-center gap-6">
        <StaticImage
          src="../../images/PlacementsPage/Image1.png"
          alt="Flipkart"
          className="contains h-[140px] w-[180px]"
        />
        <h2 className="text-2xl font-semibold text-black">
          1000+ Learners Placed In Top Companies
        </h2>
        <button className="rounded-md bg-gradient-to-b from-yellow-400 to-yellow-500 px-6 py-3 font-medium text-black shadow-lg transition hover:scale-105">
          Download Placement Report
        </button>
      </div>
    </div>
  );
};

export default PlacementHighlight;
